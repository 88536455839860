import React, { useReducer } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import './header.component.scss';

import logo from '../../assets/img/common/logo.svg';
import { ScrollLock, ScrollLockActionType } from '../../hooks/scroll-lock/scroll-lock.hooks';

interface HeaderState {
  isHeaderOpen: boolean,
  action: ScrollLockActionType,
}

interface HeaderAction {
  type: HeaderActionType,
  payload: HeaderState,
}

enum HeaderActionType {
  ACTION_HEADER_TOGGLE = 'ACTION_HEADER_TOGGLE',
  ACTION_HEADER_OPEN_LINK = 'ACTION_HEADER_OPEN_LINK',
}

const initialState = {
  isHeaderOpen: false,
  action: ScrollLockActionType.UNDEFINED,
}

const reducer: React.Reducer<HeaderState, HeaderAction> = (state: HeaderState, action: HeaderAction) => {
  switch (action.type) {
    case HeaderActionType.ACTION_HEADER_TOGGLE:
    case HeaderActionType.ACTION_HEADER_OPEN_LINK:
      return {
        ...state,
        ...action.payload,
      }
    default:
      throw new Error('Undefined Header action.');
  }
}

export const HeaderComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const navList = [
    {path: '/', label: 'Home'},
    {path: '/works', label: 'Works'},
    {path: '/contact', label: 'Contact'},
    {path: '/partner', label: 'Partner'},
    // {path: '/connect', label: 'Connect'},
  ];

  const handleToggleHeaderMenu = () => {
    const isHeaderOpen = !state.isHeaderOpen;
    const action = (isHeaderOpen) ? ScrollLockActionType.OPEN : ScrollLockActionType.CLOSE ;
    dispatch({ type: HeaderActionType.ACTION_HEADER_TOGGLE, payload: { ...state, isHeaderOpen, action }});
  }

  const handleLinkOpen = () => {
    const action = ScrollLockActionType.PAGE_LINK;
    dispatch({ type: HeaderActionType.ACTION_HEADER_OPEN_LINK, payload: { ...state, isHeaderOpen: false, action }});
  }

  return (
    <header className={'header' + (state.isHeaderOpen ? ' is-open' : '')}>
      <ScrollLock action={state.action} />
      <div className="header__logo">
        <div className="header__logo__img">
          <Link to="/" onClick={handleLinkOpen}><img src={logo} alt="Viezof" /></Link>
        </div>
      </div>
      <div className="header__button sp-only">
        <button onClick={handleToggleHeaderMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <nav className="header__nav">
        <div className="header__nav__space"></div>
        <ul className="header__nav__list">
          { navList.map((item) =>
            <li className="header__nav__list__item" key={item.path}><Link to={item.path} onClick={handleLinkOpen} className={location.pathname === item.path ? 'is-current' : ''}>{item.label}</Link></li>
          )}
        </ul>
      </nav>
    </header>
  );
}
