import { useCallback, useEffect, useState } from "react";

interface IWindowWidth {
  interval: number,// setTimeout Interval
}

export const useWindowWidth = (props: IWindowWidth | undefined) => {
  const interval = props?.interval || 100;
  const [timer, setTimer] = useState<any>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const resizeEvent = useCallback(() => {
    if (timer !== false) {
      setTimer((timer: any) => {
        clearTimeout(timer);
        return timer;
      });
    }

    setTimer(setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, interval));
  }, [interval, timer]);

  useEffect(() => {
    window.addEventListener('resize', resizeEvent);

    return () => window.removeEventListener('resize', resizeEvent);
  }, [resizeEvent]);

  return [windowWidth, setWindowWidth];
}
