import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollLockActionInterface {
  action: ScrollLockActionType,
}

export enum ScrollLockActionType {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  PAGE_LINK = 'PAGE_LINK',
  UNDEFINED = 'UNDEFINED',
}

export const ScrollLock = (props: ScrollLockActionInterface) => {
  const [scrollY, setScrollY] = useState(0);
  const [beforePath, setBeforePath] = useState('');
  const { pathname } = useLocation();

  const handleScrollLock = useCallback(() => {
    const scrollY = window.scrollY;
    setScrollY(window.scrollY);
    setBeforePath(pathname);
    document.body.style.top = `-${scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.classList.add('is-scrolllocked');
  }, [pathname]);

  const handleScrollUnLock = useCallback(() => {
    document.body.style.top = '';
    document.body.style.position = '';
    window.scrollTo(0, scrollY);
    document.body.classList.remove('is-scrolllocked');
  }, [scrollY]);

  const handleScrollUnLockWithOpenLink = useCallback(() => {
    setBeforePath(pathname);
    const behavior = (beforePath === pathname) ? 'smooth' : undefined ;
    document.body.style.top = '';
    document.body.style.position = '';
    document.body.classList.remove('is-scrolllocked');
    if (behavior !== undefined) {
      window.scrollTo({
        top: 0,
        behavior
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [beforePath, pathname]);

  useEffect(() => {
    setBeforePath(pathname);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    switch (props.action) {
      case ScrollLockActionType.OPEN:
        handleScrollLock();
        break;

      case ScrollLockActionType.CLOSE:
        handleScrollUnLock();
        break;

      case ScrollLockActionType.PAGE_LINK:
        handleScrollUnLockWithOpenLink();
        break;

      default:
        break;
    }
    // handleScrollLock,handleScrollUnLock,handleScrollUnLockWithOpenLinkを第２引数に追加すると、対象の処理が複数回走るため除外
    // eslint-disable-next-line
  }, [props]);

  return null;
}
