import React from 'react';
import { Timeline } from 'react-twitter-widgets';
import './sns.component.scss';

export const SnsComponent = () => {
  return (
    <section className="sns">
      <div className="c-content">
        <div className="c-content__ttl">
          <h2 className="sns__ttl--01 c-ttl__01">
            <span className='sns__ttl--01__en c-ttl__01__en c-anime a-move--right' data-anime-delay="300">SNS</span>
            <span className='sns__ttl--01__slash c-ttl__01__slash c-anime a-zoom--out'></span>
            <span className='sns__ttl--01__jp c-ttl__01__jp c-anime a-move--left' data-anime-delay="600">ヴィーゾフの日常</span>
          </h2>
        </div>
        <div className="c-content__conts">
          <p className="sns__lead">Twitterで仕事や趣味について投稿しています！</p>
          <div className="sns__items">
            <div className="sns__items--twitter">
              <Timeline
                dataSource={{
                  sourceType: 'profile',
                  screenName: 'viezof'
                }}
                options={{
                  width: '600',
                  height: '500'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
