import React from 'react';
import './strengths.component.scss';

export const StrengthsComponent = () => {
  return (
    <section className="strengths">
      <div className="c-content">
        <div className="c-content__ttl">
          <h2 className="strengths__ttl--01 c-ttl__01">
            <span className='strengths__ttl--01__en c-ttl__01__en c-anime a-move--right' data-anime-delay="300">Strengths</span>
            <span className='strengths__ttl--01__slash c-ttl__01__slash c-anime a-zoom--out'></span>
            <span className='strengths__ttl--01__jp c-ttl__01__jp c-anime a-move--left' data-anime-delay="600">ヴィーゾフの強み</span>
          </h2>
        </div>
        <div className="c-content__conts">
          <ul className="strengths__list">
            <li>
              <dl>
                <dt className="c-ttl__03">Webシステム開発からWebサイト制作まで対応可能です！</dt>
                <dd>
                  私たちはWebシステム開発を得意としております。特にPHP、JavaScriptのフレームワークを用いたシステム開発は数多くご依頼頂いております。<br/>
                  また、コーポレートサイトやランディングページなどの一般的なWebサイトの制作はもちろん、初期投資を抑えてECサイトを運用することが可能なカラーミーやshopserve（ショップサーブ）などのカートASPの制作実績もございます。<br/>
                  Webに関することなら幅広く対応しておりますので、お気軽にご相談ください！
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">システムのリプレースもお任せください！</dt>
                <dd>
                  最近では、既存のシステムや仕組みからWebシステムへのリプレースを行う案件が増えています。<br/>
                  Webシステムは、ネットワークとブラウザさえあれば利用できるというメリットがあり、案件のご相談も多くございます。<br/>
                  また、改修等を行った際にも、サーバーに設置してあるシステムを改修するだけで作業が完了するため、各ユーザーがアップデートしてくれない、などの問題も懸念する必要はありません。<br/>
                  他にも、社内のエクセルなどで管理している内容をWebシステムに置き換えるなど、様々なご相談を頂いております。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">仕様策定の段階からご相談ください！</dt>
                <dd>
                  仕様策定とは、Webシステム開発やWebサイト制作における設計図のようなものを作成することを指します。<br/>
                  システムやサイトにどのような機能を実装する必要があるのかという点がまとめられており、制作を行う際に欠かせないものです。<br/>
                  しかし、イメージだけで仕様策定を行うには、システムやサイトを制作した経験がなければ難しい作業です。<br/>
                  豊富な経験を元に、仕様をご相談いただいたり、私たちからご提案を行うことも可能です。<br/>
                  仕様がまとまりきっていない案件でも、まずはお気軽にお問い合わせください。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">サービスのご提案を行います！</dt>
                <dd>
                  新規にWebサイトやWebサービスを立ち上げる際に、既存のWebサービスを利用することはよくあります。<br/>
                  豊富にあるサービスのなかで、最適なサービスを選択するためには知識が必要になります。<br/>
                  私たちの経験を活かして、お客様にとって最適なサービスをご提案させていただきます。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">アジャイル開発も可能です！</dt>
                <dd>
                  サービスを立ち上げる際には、実際に機能を使って試しながらサービスを作り上げていくケースも少なくありません。<br/>
                  改修を行わずに済むような機能もあれば、納得行くまでに何十回も改修を行う機能も出てきます。<br/>
                  ユーザーの目線に立って、本当に使いやすいサービスを作るためにお力添えさせていただきます。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">定期的な更新業務もお任せください！</dt>
                <dd>
                  定期的な作業を依頼する際に、毎回お見積りをご案内しながら依頼いただくというのは手間になってしまうかと存じます。<br/>
                  費用や作業量に応じた定期契約をしていただくことで、ご依頼のフローを簡略化していただくことができます。
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
