import { ISourceOptions } from 'tsparticles-engine';

export const CorporateColors = ['#c30d23', '#0068B7', '#F8B62D', '#006934'];

export const HomeParticlesOptions: ISourceOptions = {
  fullScreen: true,
  particles: {
    number: {
      value: 30,
      density: {
        enable: true,
        value_area: 1000,
      },
    },
    color: {
      value: [
        '#c30d23', '#0068B7', '#F8B62D', '#006934'
      ],
    },
    opacity: {
      value: 0.2,
    },
    lineLinked: {
      enable: true,
      color: 'random',
      distance: 200,
      opacity: 0.2,
      width: 1,
    },
    move: {
      enable: true,
    }
  },
  interactivity: {
    events: {
      onHover: {
        enable: true,
        mode: 'repulse',
      },
      onClick: {
        enable: true,
        mode: 'push',
      },
    },
    modes: {
      repulse: {
        distance: 200,
      },
    }
  }
}

export const LowerParticlesOptions: ISourceOptions = {
  fullScreen: true,
  emitters: {
    direction: 'top-left',
    position: {
      x: 100,
      y: 100,
    },
    size: {
      width: 0,
      height: 0,
    },
    rate: {
      delay: .2,
      quantity: 1,
    }
  },
  particles: {
    number: {
      value: 0,
      limit: 60,
      density: {
        enable: true,
        value_area: 1000,
      }
    },
    color: {
      value: '#ccc',
    },
    size: {
      value: {
        min: 2,
        max: 5
      },
    },
    opacity: {
      value: .25
    },
    move: {
      enable: true,
      outMode: 'destroy',
      speed: {
        min: 1,
        max: 3,
      },
      straight: false,
    },
  },
  interactivity: {
    detectsOn: 'canvas',
    events: {
      resize: true,
    }
  }
}
