import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { WorksComponent } from './pages/works/works.component';
// import { ConnectComponent } from './pages/connect/connect.component';
import { NotFoundComponent } from './pages/not-found/notfound.component';

export class Routes extends React.Component<{}, {}> {
  render() {
    return (
      <>
        <HeaderComponent />
        <Switch>
          <Route exact path="/" component={HomeComponent} />
          <Route exact path="/works" component={WorksComponent} />
          <Route exact path="/contact" component={ContactComponent} />
          <Route exact path="/partner" component={PartnerComponent} />
          {/*
          <Route exact path="/connect" component={ConnectComponent} />
          */}
          <Route component={NotFoundComponent} />
        </Switch>
        <FooterComponent />
      </>
    );
  }
}
