import { useState, useEffect, useCallback } from 'react';

export const ScrollAnimation = () => {
  const trigger = (window.innerHeight / 5) * 4;
  const [items, setItems] = useState<any>([]);

  const animationRun = useCallback(() => {
    if (! items.length) return false;

    items.forEach((el: Element) => {
      const elTop = el.getBoundingClientRect().top;

      if (elTop < trigger) {
        el.classList.add('c-anime--animated');
      }
    });
  }, [items, trigger]);

  useEffect(() => {
    setItems(document.querySelectorAll('.c-anime'));
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', animationRun);
    animationRun();// 初回処理

    return () => window.removeEventListener('scroll', animationRun);
  }, [items, animationRun]);

  return null;
}
