import React from 'react';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import './notfound.component.scss';
import { LowerParticlesComponent } from '../../components/lower-particles/lower-particles.component';

export const NotFoundComponent = () => {
  return (
    <>
      <Helmet>
        <title>ページが見つかりませんでした｜宮城県仙台市のシステム開発 ヴィーゾフ -Viezof-</title>
        <meta name="description" content="お探しのページが見つかりませんでした。ページが削除されたか、URLの指定に誤りがある可能性があります。" />
      </Helmet>
      <div className="notfound">
        <div className="notfound__page_title c-page_title">
          <h1 className="c-page_title__txt">Not Found<span className="c-page_title__txt__ja">ページが見つかりませんでした</span></h1>
        </div>
        <div className="notfound__conts">
          <LowerParticlesComponent />
          <div className='c-lower_content'>
            <p>お探しのページが見つかりませんでした。<br/>
            ページが削除されたか、URLの指定に誤りがある可能性があります。</p>
            <div className="notfound__conts__contact c-btn__01">
              <Link to="/">トップページへ戻る</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
