import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import './contact.component.scss';
import { LowerParticlesComponent } from '../../components/lower-particles/lower-particles.component';

interface formState {
  sending: boolean;
  submitted: boolean;
  sendError: boolean;
  input: formInputState;
  error: formInputState;
}

interface formInputState {
  name: string;
  company: string;
  email: string;
  kind: string;
  content: string;
}

const initialState = {
  sending: false,
  submitted: false,
  sendError: false,
  input: {
    name: '',
    company: '',
    email: '',
    kind: '',
    content: '',
  },
  error: {
    name: '',
    company: '',
    email: '',
    kind: '',
    content: '',
  }
}

export class ContactComponent extends React.Component<{}, formState> {
  constructor(props: any) {
    super(props);

    this.state = initialState;
  }

  private onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.onChangeSetState(e);
  }

  private onChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.onChangeSetState(e);
  }

  private onChangeSetState = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      input: {...this.state.input, [name]: value }
    });

    if (event.target.classList.contains('required') === true) {
      this.setState({
        error: {...this.state.error, [name]: this.validator(name, value)}
      });
    }
  }

  private validator = (name: string, value: string) => {
    if (name === 'email') {
      return this.emailValidation(value);
    }
    else {
      return this.contentValidation(value);
    }
  }

  private emailValidation = (value: string) => {
    let result = '';
    const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

    if (!value) {
      result = '必須項目です。';
    }
    else if (!regex.test(value)) {
      result = 'メールアドレスの形式ではありません。';
    }

    return result;
  }

  private contentValidation = (value: string) => {
    let result = '';
    if (!value) {
      result = '必須項目です。';
    }

    return result;
  }

  private sendForm = (e: React.FormEvent) => {
    e.preventDefault();

    if (this.state.sending === true)
      return false;

    this.setState({sending: true});

    fetch('https://viezof.microcms.io/api/v1/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-MICROCMS-API-KEY': 'bf07f329471445a9815cd88c2737b0b52710',
      },
      body: JSON.stringify({
        name: this.state.input.name,
        company: this.state.input.company,
        email: this.state.input.email,
        kind: this.state.input.kind,
        content: this.state.input.content
      })
    })
    .then(response => {
      if (!response.ok) {
        let error = new Error('HTTP status code: ' + response.status);

        throw error;
      }

      this.setState(initialState);
      this.setState({
        submitted: true
      });
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        sending: false,
        sendError: true
      });
    });
  }

  private renderError = (message: string) => {
    if (message) {
      return <p className="c-form__error">{message}</p>;
    }
    else {
      return '';
    }
  }

  private renderMessage = () => {
    if (this.state.submitted === true) {
      return <p className="contact__message success">お問い合わせいただきありがとうございます。折り返し担当者よりご連絡差し上げます。<br/>通常は3営業日以内にご返信差し上げますが、もし返信がない場合はメールが迷惑メールに分類されていないかをご確認の上、お手数ですが再度フォームよりお問い合わせください。</p>
    }
    else if (this.state.sendError === true) {
      return <p className="contact__message error">フォームの送信に失敗しました。<br/>お手数をおかけいたしますが、時間を置いて再度お試しいただくか、<br/><a href="mailto:info@viezof.com">info@viezof.com</a>まで直接お問い合わせください。</p>
    }
  }

  private checkInput = () => {
    if (
      this.state.sending
      || !this.state.input.name
      || !this.state.input.email
      || !this.state.input.kind
      || !this.state.input.content
      || this.state.error.name
      || this.state.error.email
      || this.state.error.kind
      || this.state.error.content
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  render() {
    return(
      <>
        <Helmet>
          <title>お問い合わせ｜宮城県仙台市のシステム開発 ヴィーゾフ -Viezof-</title>
          <meta name="description" content="ヴィーゾフのお問い合わせページです。Webシステム開発やWordPress構築、ECサイト構築など、Web制作に関するご相談はこちらからお送りください。" />
        </Helmet>
        <div className="contact">
          <div className="contact__page_title c-page_title">
            <h1 className="c-page_title__txt">Contact<span className="c-page_title__txt__ja">お問い合わせ</span></h1>
          </div>
          <div className="contact__conts">
            <LowerParticlesComponent />
            <div className='c-lower_content'>
              <p className="contact__note"><span className="contact__note__required">*</span>は入力必須項目です。</p>
              <form onSubmit={this.sendForm} className="c-form">
                <dl>
                  <dt>お名前<span className="c-form__required">*</span></dt>
                  <dd>
                    <input className="c-form__input required" type="text" name="name" value={this.state.input.name} onChange={this.onChangeInput}/>
                    {this.renderError(this.state.error.name)}
                  </dd>
                </dl>
                <dl>
                  <dt>会社名</dt>
                  <dd><input className="c-form__input" type="text" name="company" value={this.state.input.company} onChange={this.onChangeInput}/></dd>
                </dl>
                <dl>
                  <dt>メールアドレス<span className="c-form__required">*</span></dt>
                  <dd>
                    <input className="c-form__input required" type="text" name="email" value={this.state.input.email} onChange={this.onChangeInput}/>
                    {this.renderError(this.state.error.email)}
                  </dd>
                </dl>
                <dl>
                  <dt>お問い合わせ種別<span className="c-form__required">*</span></dt>
                  <dd>
                    <ul className="contact__list__contact_type">
                      <li><label><input className="c-form__radio required" type="radio" name="kind" value="案件に関するお問い合わせ" onChange={this.onChangeInput} checked={this.state.input.kind === '案件に関するお問い合わせ'}/>案件に関するお問い合わせ</label></li>
                      <li><label><input className="c-form__radio required" type="radio" name="kind" value="制作パートナーに関するお問い合わせ" onChange={this.onChangeInput} checked={this.state.input.kind === '制作パートナーに関するお問い合わせ'}/>制作パートナーに関するお問い合わせ</label></li>
                      <li><label><input className="c-form__radio required" type="radio" name="kind" value="その他" onChange={this.onChangeInput} checked={this.state.input.kind === 'その他'}/>その他</label></li>
                    </ul>
                    {this.renderError(this.state.error.kind)}
                  </dd>
                </dl>
                <dl>
                  <dt>お問い合わせ内容<span className="c-form__required">*</span></dt>
                  <dd>
                    <textarea className="c-form__input required" name="content" value={this.state.input.content} onChange={this.onChangeTextarea} cols={30} rows={10}/>
                    {this.renderError(this.state.error.content)}
                  </dd>
                </dl>
                <div className="contact__submit">
                  <button className="c-form__submit" disabled={this.checkInput()}>送信する</button>
                </div>
              </form>
              {this.renderMessage()}
            </div>
          </div>
        </div>
      </>
    )
  }
}
