import * as React from 'react';
import './footer.component.scss';

export class FooterComponent extends React.Component<{}, {}> {
  render() {
    return (
      <footer className="footer">
        <div className="footer__copy"><small>&copy; 2016 Viezof</small></div>
      </footer>
    )
  }
}
