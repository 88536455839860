import React from 'react';
import './about.component.scss';

export const AboutComponent = () => {
  return (
    <section className="about">
      <div className="c-content">
        <div className="c-content__ttl">
          <h2 className="about__ttl--01 c-ttl__01">
            <span className='about__ttl--01__en c-ttl__01__en c-anime a-move--right' data-anime-delay="300">About</span>
            <span className='about__ttl--01__slash c-ttl__01__slash c-anime a-zoom--out'></span>
            <span className='about__ttl--01__jp c-ttl__01__jp c-anime a-move--left' data-anime-delay="600">ヴィーゾフについて</span>
          </h2>
        </div>
        <div className="c-content__conts">
          <p>
            私たちは、2016年から宮城県仙台市に拠点を置いて業務を行っているエンジニア集団です。<br/>
            ヴィーゾフには、Web制作会社で5年以上の実務経験があり、スキルに自信があるメンバーが在籍しています。
          </p>

          <p>
            Webシステム開発、WebサイトやECサイトの制作など、Webを専門に幅広く対応を行っております。<br/>
            特に得意としているWebシステム開発は、数多くご依頼を頂いております。
          </p>

          <p>
            制作において、実際にWebシステムやWebサイトを利用するユーザーの目線を大事にして開発することを心がけております。その思いから、お客様や案件によっては機能や要望の意図を確認させていただき、私たちからご提案をさせていただくこともございます。
          </p>
          <p>
            ユーザーにとって、より利用しやすいWebシステムやWebサイトを制作するために尽力してまいります。
          </p>

          <section className='about__sec--01 c-sec__dep--01'>
            <h3 className="c-ttl__02">ヴィーゾフの由来</h3>
            <p>
              ヴィーゾフという言葉には「挑戦」という意味があります。<br/>
              私たちは、お客様の抱えている課題や要望に応えるため、常に「挑戦」をしていきたいと考えています。
            </p>
          </section>
        </div>
      </div>
    </section>
  );
}
