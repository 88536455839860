import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import './index.scss';
import { Routes } from './Routes';
import TagManager from 'react-gtm-module'
import { HelmetProvider } from 'react-helmet-async';
// import * as serviceWorker from './serviceWorker';

import * as _history from 'history';

var history = _history.createBrowserHistory();

const tagManagerArgs = {
  gtmId: 'GTM-TQ59C4G'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Router history={history}>
    <HelmetProvider>
      <Routes />
    </HelmetProvider>
  </Router>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
