import React, { useCallback, useEffect, useState } from 'react';
import Particles from 'react-particles';
import type { Engine, Container } from 'tsparticles-engine';
import { loadFull } from 'tsparticles';
import { LowerParticlesOptions, CorporateColors } from '../config/particles-config';
import { sample as _sample, cloneDeep as _cloneDeep, set as _set } from 'lodash';
import { useWindowWidth } from '../../hooks/use-window-width/use-window-width.hooks';

export const LowerParticlesComponent = () => {
  const [options, setOptions] = useState(LowerParticlesOptions);
  const [windowWidth] = useWindowWidth({interval: 300});

  const init = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const loaded = useCallback(async (container: Container | undefined) => {
    if (windowWidth < 768) {
      container?.stop();
    } else {
      await container?.start();
    }
  }, [windowWidth])

  useEffect(() => {
    const color = _sample(CorporateColors);
    setOptions(origin => {
      const options = _cloneDeep(origin);
      _set(options, 'particles.color.value', color);

      return options;
    });
  }, []);

  return (
    <>
      <Particles options={options} loaded={loaded} init={init} />
    </>
  );
}
