import React from 'react';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import './partner.component.scss';
import { LowerParticlesComponent } from '../../components/lower-particles/lower-particles.component';

export const PartnerComponent = () => {
  return (
    <>
      <Helmet>
        <title>パートナー募集｜宮城県仙台市のシステム開発 ヴィーゾフ -Viezof-</title>
        <meta name="description" content="ヴィーゾフのパートナー募集ページです。ご協力いただけるフロントエンドエンジニア、Webコーダー、デザイナーの方を募集しております。" />
      </Helmet>
      <div className="partner">
        <div className="partner__page_title c-page_title">
          <h1 className="c-page_title__txt">Partner<span className="c-page_title__txt__ja">パートナー募集</span></h1>
        </div>
        <div className="partner__conts">
          <LowerParticlesComponent />
          <div className='c-lower_content'>
            <p>ヴィーゾフでは、Web制作業務においてご協力いただけるビジネスパートナー様を募集しております。<br/>
            下記の募集要項をご確認の上、スキルや実績を添えてお問い合わせフォームよりご応募ください。<br/>
            また、パートナー様で抱えきれない案件を対応することも可能ですので、そちらもお気軽にご相談ください。</p>

            <p>切磋琢磨しながら、共に成長できるパートナー様と一緒にお仕事ができれば幸いです！</p>

            <section className="partner__conts__section c-lower_content__section">
              <h2 className="partner__conts__section__ttl c-ttl__02">Webエンジニア</h2>
              <p>HTML/CSSコーディング、ReactやAngularを使用したフロントエンド構築、CMSやフレームワークを使用したバックエンド構築など、お持ちのスキルに合わせて業務をご相談させていただきます。</p>
            </section>

            <section className="partner__conts__section c-lower_content__section">
              <h2 className="partner__conts__section__ttl c-ttl__02">デザイナー</h2>
              <p>PhotoshopやXDを使用したWebデザインや、イラストの作成、写真撮影など、デザインに関する業務をご相談させていただきます。</p>
            </section>

            <section className="partner__conts__section c-lower_content__section">
              <h2 className="partner__conts__section__ttl c-ttl__02">ご応募について</h2>
              <p>いずれもご自身のスキルや実績を記載の上でお問い合わせフォームよりご応募ください。<br/>
              折り返しメールにてご連絡差し上げます。<br/>
              ※必ずしも案件のご相談をさせていただくわけではありませんので、予めご了承ください。</p>
            </section>

            <div className="partner__conts__contact c-btn__01">
              <Link to="/contact">応募フォーム</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
