import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet-async';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import type { Engine, Container } from 'tsparticles-engine';
import { HomeParticlesOptions } from '../../components/config/particles-config';
import './home.component.scss';
import { ScrollAnimation } from '../../hooks/scroll-animation/scroll-animation.hooks';
import { useSpCheck } from '../../hooks/use-sp-check/use-sp-check.hooks';
import { AboutComponent } from './about/about.component';
import { StrengthsComponent } from './strengths/strengths.component';
import { FlowComponent } from './flow/flow.component';
import { SkillsComponent } from './skills/skills.component';
import { SnsComponent } from './sns/sns.component';
import {
  cloneDeep as _cloneDeep,
  padStart as _padStart,
  findIndex as _findIndex,
  get as _get,
  set as _set
} from 'lodash';
import logoSvg from '../../assets/img/home/logo.svg';
import copySvg from '../../assets/img/home/copy.svg';

const inicialState = [
  {index: 1, state: false},
  {index: 2, state: false},
  {index: 3, state: false},
];

export const HomeComponent = () => {
  const [slide, setSlide] = useState(inicialState);
  const [isSP] = useSpCheck();
  const timer = useRef<any>(null);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    if (isSP) {
      await container?.stop();
    } else {
      await container?.start();
    }
  }, [isSP]);

  useEffect(() => {
    // 初期のスライドをtrueに変更する
    const currentSlide = _cloneDeep(slide);
    _set(currentSlide, '0.state', true);
    setSlide(currentSlide);

    timer.current = setInterval(() => crossFade(), 8000);

    return () => clearInterval(timer.current);
    // 値の変更を検知したくないため引数は設定しない
    // eslint-disable-next-line
  }, []);

  const crossFade = useCallback(() => {
    setSlide(slide => {
      const currentSlide = _cloneDeep(slide);
      const index = _findIndex(currentSlide, 'state');
      const target = (_get(currentSlide, index + 1)) ? index + 1 : 0 ;

      _set(currentSlide, index + '.state', false);
      _set(currentSlide, target + '.state', true);

      return currentSlide;
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>宮城県仙台市のシステム開発 ヴィーゾフ -Viezof-</title>
        <meta name="description" content="ヴィーゾフは宮城県仙台市でシステム開発を行っているエンジニア集団です。Webシステム開発、WordPress構築など、Webに関する業務はお任せください。" />
      </Helmet>
      <ScrollAnimation />
      <div>
        <Particles options={HomeParticlesOptions} loaded={particlesLoaded} init={particlesInit} />
        <div className="main_visual">
          { slide.map((item) =>
            <CSSTransition in={item.state} classNames="fade" timeout={1000} key={item.index}>
              <div className={'main_visual__slide main_visual__slide__' + _padStart(item.index.toString(), 2, '0')}></div>
            </CSSTransition>
          )}
          <div className='main_visual__main'>
            <div className="main_visual__logo">
              <img src={logoSvg} alt="Viezof" />
            </div>
            <div className="main_visual__copy a-fade--in c-anime--animated" data-anime-delay="600">
              <img src={copySvg} alt="ITで東北を支援する" />
            </div>
          </div>
        </div>

        <AboutComponent />
        <StrengthsComponent />
        <FlowComponent />
        <SkillsComponent />
        <SnsComponent />
      </div>
    </>
  );
}
