import React from 'react';
import { Link } from 'react-router-dom';
import './flow.component.scss';

export const FlowComponent = () => {
  return (
    <section className="flow">
      <div className="c-content">
        <div className="c-content__ttl">
          <h2 className="flow__ttl--01 c-ttl__01">
            <span className='flow__ttl--01__en c-ttl__01__en c-anime a-move--right' data-anime-delay="300">Flow</span>
            <span className='flow__ttl--01__slash c-ttl__01__slash c-anime a-zoom--out'></span>
            <span className='flow__ttl--01__jp c-ttl__01__jp c-anime a-move--left' data-anime-delay="600">ご依頼の流れ</span>
          </h2>
        </div>
        <div className="c-content__conts">
          <p className="c-lead">ヴィーゾフに制作をご依頼いただく際の流れをご紹介します。</p>
          <ol className="flow__list">
            <li>
              <dl>
                <dt className="c-ttl__03">ご相談</dt>
                <dd>
                  ご相談いただく内容をまとめて<Link to="/contact">お問い合わせフォーム</Link>よりご連絡ください。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">お見積り</dt>
                <dd>
                  制作に必要な情報をご提示いただきましたら、お見積りをご案内させていただきます。<br/>
                  ご依頼いただく作業により必要になる情報（デザインや仕様書等）が異なりますので、ご相談を頂いた際にご連絡させていただきます。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">ご発注</dt>
                <dd>
                  ご案内したお見積書にて内容（金額や納期等）をご確認いただき、問題ない場合は発注の旨のご連絡をお願いいたします。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">作業開始</dt>
                <dd>
                  発注のご連絡を頂きましたら作業を開始させていただきます。<br/>
                  作業中にご確認が必要な事項等が発生した場合は、別途ご連絡させていただく場合がございます。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">テスト環境ご案内</dt>
                <dd>
                  作業が完了しましたら、ヴィーゾフで準備したテスト環境、またはお客様にご準備頂いたテスト環境にデータをアップしてご連絡差し上げます。一次提出、二次提出等の作業量に応じて複数回でのご案内を行うことも可能です。分割でのご提出をご希望の場合は、ご依頼の際にご相談ください。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">修正作業</dt>
                <dd>
                  実際に出来上がったサイトをお客様に見ていただき、修正が必要な箇所がないかをご確認いただきます。<br/>
                  修正箇所がございましたらまとめてご連絡ください。お客様の確認が完了次第、対応させていただきます。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="c-ttl__03">納品</dt>
                <dd>
                  本番環境に制作したデータをアップ、またはお客様にデータをお渡しする形で納品となります。
                </dd>
              </dl>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
}
