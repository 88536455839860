import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { MuuriComponent } from 'muuri-react';
import { each as _each, findIndex as _findIndex, get as _get, set as _set, sortBy as _sortBy } from 'lodash';
import './works.component.scss';
import worksJson from '../../assets/json/works.json';
import { LowerParticlesComponent } from '../../components/lower-particles/lower-particles.component';

export const WorksComponent = () => {
  const [works, setWorks] = useState<any>({works: [], tags: []});
  const [tagFilter, setTagFilter] = useState<string>('');

  const init = useCallback(() => {
    let works: any = [];
    let tags: any = [];

    _each(worksJson.works, (work) => {
      works.push(work);

      // タグの一覧を生成
      _each(work.tags, (tag) => {
        const i = _findIndex(tags, {name: tag});
        if (i === -1) {
          tags.push({name: tag, count: 1});
        } else {
          const count = _get(tags, i + '.count') + 1;
          _set(tags, i + '.count', count);
        }
      });
      tags = _sortBy(tags, 'name');
    });

    setWorks({works, tags});
  }, [setWorks]);

  const onChangeFilter = useCallback((event: any) => {
    const name = event.currentTarget.getAttribute('data-name');

    setTagFilter((origin: string) => {
      return (origin === name) ? '' : name;
    });
  }, [setTagFilter]);

  const itemFilter = useCallback(({work}) => {
    if (tagFilter === '') {
      return true;
    }

    return _findIndex(work.tags, (v) => v === tagFilter) !== -1;
  }, [tagFilter]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <Helmet>
        <title>制作実績｜宮城県仙台市のシステム開発 ヴィーゾフ -Viezof-</title>
        <meta name="description" content="ヴィーゾフの制作実績ページです。WebシステムやWordPress、ECサイトなど、これまで制作した案件をご紹介いたします。" />
      </Helmet>
      <div className="works">
        <div className="works__page_title c-page_title">
          <h1 className="c-page_title__txt">Works<span className="c-page_title__txt__ja">制作実績</span></h1>
        </div>
        <div className="works__conts">
          <LowerParticlesComponent />
          <div className='c-lower_content'>
            <p className='works__lead'>ヴィーゾフが、今までに制作を担当した案件の一部をご紹介いたします。</p>

            <ul className='works__tags'>
              { works.tags.map((item: any, index: number) => {
                return (
                  <li key={index}
                      className={[
                        'works__tags__item',
                        (tagFilter === item.name) ? 'is-selected' : '',
                      ].join(' ')}
                      onClick={onChangeFilter}
                      data-name={item.name}>
                    {item.name}<span className='works__tags__item__cnt'>{item.count}</span>
                  </li>
                )
              })}
            </ul>

            <MuuriComponent containerClass="works__items" propsToData={(work) => (work)} filter={itemFilter}>
              { works.works.map((work: any, index: number) => {
                return <MuuriItem key={index} work={work} />
              })}
            </MuuriComponent>
          </div>
        </div>
      </div>
    </>
  );
}

const MuuriItem = (props: any) => {
  return (
    <div>
      <section className='works__card'>
        <h2 className='works__card__title'>{props.work.title}</h2>
        <p className='works__card__desc'>{props.work.description}</p>
        <ul className='works__card__tags'>
          { props.work.tags.map((tag: string, tagI: number) => {
            return <li key={tagI} className='works__card__tags__item'>{tag}</li>
          })}
        </ul>
      </section>
    </div>
  );
}
