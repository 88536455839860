import { useCallback, useEffect, useState } from "react";

export const useSpCheck = () => {
  const [isSP, setIsSP] = useState(false);

  const checkSP = useCallback((value: number) => {
    const result = (value < 768)
      ? true
      : false;

    return result;
  }, []);

  const resizeEvent = useCallback(() => {
    setIsSP(checkSP(window.innerWidth));
  }, [checkSP]);

  useEffect(() => {
    setIsSP(checkSP(window.innerWidth));
    window.addEventListener('resize', resizeEvent);

    return () => window.removeEventListener('resize', resizeEvent);
  }, [checkSP, resizeEvent]);

  return [isSP, setIsSP];
}
