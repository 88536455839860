import React from 'react';
import './skills.component.scss';

export const SkillsComponent = () => {
  return (
    <section className="skill">
      <div className="c-content">
        <div className="c-content__ttl">
          <h2 className="skill__ttl--01 c-ttl__01">
            <span className='skill__ttl--01__en c-ttl__01__en c-anime a-move--right' data-anime-delay="300">Skill</span>
            <span className='skill__ttl--01__slash c-ttl__01__slash c-anime a-zoom--out'></span>
            <span className='skill__ttl--01__jp c-ttl__01__jp c-anime a-move--left' data-anime-delay="600">業務での採用実績</span>
          </h2>
        </div>
        <div className="c-content__conts">
          <p className="skill__lead">私たちが主に制作で使用している、言語やサービス等をご紹介します。</p>
          <div className='skill__list'>
            <dl>
              <dt>Code</dt>
              <dd>PHP</dd>
              <dd>MySQL</dd>
              <dd>JavaScript</dd>
              <dd>TypeScript</dd>
              <dd>HTML</dd>
              <dd>CSS</dd>
            </dl>
            <dl>
              <dt>Frame work</dt>
              <dd>Laravel</dd>
              <dd>React</dd>
              <dd>Vue.js</dd>
              <dd>Angular</dd>
            </dl>
            <dl>
              <dt>CMS</dt>
              <dd>WordPress</dd>
              <dd>MovableType</dd>
              <dd>microCMS</dd>
            </dl>
            <dl>
              <dt>EC</dt>
              <dd>RMS</dd>
              <dd>カラーミー</dd>
              <dd>shopserve</dd>
              <dd>らくうるカート</dd>
            </dl>
            <dl>
              <dt>Other</dt>
              <dd>Stripe</dd>
              <dd>TailwindCSS</dd>
              <dd>GraphQL</dd>
              <dd>Git</dd>
            </dl>
          </div>
          <p className="skill__notes">※こちらに記載されていない言語等の実績もございますので、お気軽にお問い合わせください。</p>
        </div>
      </div>
    </section>
  );
}
